.homepage,
.std-layout,
.two-row-layout,
.wide-two-row-layout {
    background-color: $white;
    padding-top: 0.64em;
    h1,
    h2,
    h3 {
        &.no-top-margin {
            margin-top: 0;
        }
        &.underlined {
            border-bottom: 1px solid $primary-color;
        }
        &.compressed {
            color: $secondary-color;
            text-transform: lowercase;
            font-weight: 600;
        }
    }
}

.homepage {
    .najcitanije {
        .h4 {
            padding-left: 1rem;
        }
        .row {
            margin-bottom: 0.64em;
        }
        .broj {
            color: $secondary-color-shade-3;
            font-size: 2.074em;
            line-height: 1;
            text-align: center;
        }
        .naslov {
            border-top: 1px solid $secondary-color-shade-3;
            padding-left: $global-margin / 2;

            a {
                color: $darker-gray;
                font-family: $header-font-family;
                // font-size: 0.8em;
                &:hover {
                    color: $darker-gray;
                    text-decoration: underline;
                }
            }
        }
    }
    .sve-novosti {
        text-align:right;
        a {
            i {
                padding-right: 0.41em;
            }
            background-color: $light-gray;
            color: $darker-gray;
            font-weight: 600;
            border-radius: 6px;
            padding: 0.262em 0.512em;
            &:hover {
                background-color: $secondary-color-dark-1;
                color: $light-gray;
            }
        }
    }
}

.sidebar {
    &.page-info-container {
        @include -zf-breakpoint-value('medium', $grid-column-gutter) {
            $padding: rem-calc($-zf-bp-value) / 4;
            padding-top: $padding / 2 ;
        }
    }
    &.page-subtitle {
        font-size: $small-font-size;
    }
    &.page-date {
        font-size: $small-font-size;
    }
    &.page-intro {
        font-family: $text-font-family;
    }
    h1, h2, h3, h4, h5, h6 {
        &.compressed {
            margin-top: 0.625em;
        }
    }
    &.related.documents {
        .document {
            margin-top: 0.41em;
            line-height: $header-lineheight;
            padding-left: 1.64em;
            text-indent: -0.82em;
            i {
                color: $secondary-color;
                padding: 0 0.41em;
            }
            a {
                color: $darker-gray;
            }
            a:hover {
                color: $darker-gray;
                text-decoration: underline;
            }
        }
    }
}
