// Layout
.header-c {
    background-color: $secondary-color;
    border-bottom: 1px solid $medium-gray;
    .header-ic {
        background: url("/static/home/img/logo_agencije_8_me.png") no-repeat;
        min-height: 150px;
    }
}
.header2-c {
    background-color: $secondary-color;
}

.header3-c {
    background-color: $secondary-color-shade-4;
    .header3-ic {
        background-color: $secondary-color-shade-3;
    }
    .sub-ic {
        min-height: 1.5rem;
    }
}

//Title bar
.title-bar {
    a {
        color: $white;
        &:hover {
            text-decoration: underline;
        }
    }
}

// Menues
.secondary-menu.menu {
    float: right;
    // font-weight: 600;
    // font-size: 0.93em;
    margin-top: 0.3815rem;
    // text-transform: lowercase;
    li {
        margin: 0 0.3052em;
        border-bottom: 1px solid transparent;
        &:hover, &.active {
            border-bottom: 1px solid $white;
        }
        a {
            color: $white;
            line-height: $global-lineheight - 0.2;
            margin: 0.1rem 0;
            // padding: $global-padding*0.5;
            padding: 0 0.3052rem;
            // padding: .7em 0.3em 0.12em;
        }
        > ul::after {
            position: absolute;
            left: 10%;
            margin-left: -20px;
            top: -8px;
            width: 0;
            height: 0;
            content:'';
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $light-gray;
        }
        > ul {
            margin-top: 0.8em;
            padding: 0.5em;
            border-radius: 2px;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            > li {
                border-bottom: none;
                &:hover, &.active {
                    border-bottom: none;
                }
                &:hover{
                    text-decoration: underline;

                }
                > a {
                    line-height: $global-lineheight;
                    white-space: nowrap;
                    color: $secondary-color-dark-1;
                    font-weight: normal;
                    padding: 0  0.4768em;
                }
            }
        }
    }
}
.global-search-c {
    .global-search-group {
        margin: 0.7451em;
    }
}

.technical-menu {
    float: right;
    margin: 2.1em 0.5em 0.4768em 0;
    li a {
        padding: $global-padding*0.4 $global-padding*0.2;
    }
}

.main-menu {
    // text-transform: lowercase;
    min-height: 1.5rem;
    margin: 0.1953rem 0;
    li {
        a {
            line-height: $global-lineheight - 0.2;
            color: $white;
            padding: 0 0.1em;
            margin: 0.1rem 0.4em;
            border-bottom: 1px solid transparent;
            &:hover {
                border-bottom: 1px solid $white;
            }
        }
        &.active > a {
            border-bottom: 1px solid $white;
        }
        &.menu-link-show-all {
            background-color: $secondary-color;
            position: absolute;
            right: 0;
            top: 0;
            i {
                padding: 0.4em 0 0 0.4em;
                color: $white;
            }
            a {
                display: inline-block;
            }
        }
    }
}

ul.menu-breadcrumbs,
ul.primary-submenu {
    display: table-cell;
    text-transform: lowercase;
    li {
        margin: 0.1953rem 0;
        a,
        span.title {
            line-height: $global-lineheight - 0.2;
            padding: 0 0.1em;
            display: inline-block;
            margin: 0.1rem 0.4rem;
            border-bottom: 1px solid transparent;
        }
    }
}
ul.menu-breadcrumbs {
    @include breakpoint(medium) {
        border-right: 1px solid $light-gray;
    }
    li {
        a,
        span.title {
            color: $secondary-color;
        }
        a:hover {
            color: $secondary-color;
            border-bottom: 1px solid $secondary-color;
        }
    }
}
ul.primary-submenu {
    li {
        a {
            color: $darker-gray;
        }
        a:hover,
        a.active,
        &.active > a {
            color: $darker-gray;
            border-bottom: 1px solid $darker-gray;
        }
    }
}

.full-menu-c {
    ul.full-menu {
        list-style: none;
        list-style-image: none;
        // a {
            // text-transform: lowercase;
        // }
        > li {
            // sections
            position: relative;
            display: block;
            clear: both;
            padding-left: 12rem;
            height: 1.953rem;
            // min-height: 1.953rem;
            overflow: hidden;
            box-shadow: inset 0 0.0625rem $light-gray;
            -webkit-box-shadow: inset 0 0.0625rem $light-gray;
            > a {
                padding: 0.1953rem 0 0.1953rem 1.563rem;
                background: $secondary-color;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 12em;
                box-sizing: border-box;
                float: left;
                border-top: 0.0625rem solid $secondary-color-shade-1;
                color: $white;
                line-height: $global-lineheight - 0.2;
                span {
                    padding: 0 0.1em;
                    padding-bottom: 0.05em;
                    margin: 0.1rem 0;
                }
            }
            > a:hover,
            &.active > a {
                span {
                    border-bottom: 1px solid $white;
                }
            }
            > ul {
                // childrens
                box-sizing: border-box;
                // min-height: 2em;
                // max-height: 2em;
                // padding-top: 0.1875em;
                overflow: hidden;
                > li {
                    margin: 0.1953rem 0;
                    > a {
                        float: left;
                        white-space: nowrap;
                        line-height: $global-lineheight - 0.2;
                        padding: 0 0.1em;
                        display: inline-block;
                        margin: 0.1rem 0.4rem;
                        border-bottom: 1px solid transparent;

                        color: $darker-gray;
                    }
                    &.active > a,
                    > a:hover,
                    > a.active {

                        border-bottom: 1px solid $darker-gray;
                    }
                }
            }
        }


    }
}


.content-c {
    background-color: $light-gray;
    min-height: 60vh;
    .homepage,
    .std-layout,
    .two-row-layout,
    .wide-two-row-layout
     {
        min-height: 60vh;
        background-color: $white;
    }
}

//footer
.footer-c {
    background-color: $dark-gray;
    color: $white;

    .footer-ic {
        min-height: 4rem;
        .menu-c {
            .bottom-menu {
                font-size: 0.8rem;
                .menu-title {
                    margin-top: $global-margin;
                    margin-bottom: $global-margin*0.5;
                    font-size: 1.25rem;
                    font-weight: 600;

                }
                .menu {
                    text-transform: capitalize;
                    a {
                        padding: 0;
                        margin: $global-margin*0.4 $global-margin*0.4;
                        color: $white;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .hoce-linkove {
            font-size: 2rem;
            text-align: right;
            padding: 0.5*$global-margin;
            a {
                color: $white;
                padding: 0 0.25*$global-padding;
                &:hover {
                    color: $medium-gray;
                }
            }
        }
        .copyright {
            font-size: $small-font-size;
            margin: $global-margin/2 0  $global-margin 0;
        }
    }
}

.wagtail-userbar {
    top:300px;
}

.pagination-c {
    margin-bottom: 1rem;
    .total {
        padding-left: 1rem;
        line-height: 1.45;
        font-size: 0.8rem;
        color: $secondary-color-shade-2;
    }
    .pagination {
        margin-bottom: 0;
    }
    .sort-order {
        font-size: 0.8rem;
        color: $secondary-color-shade-2;
        span {
            cursor: pointer;
            cursor: hand;
        }
        i {
            font-size: 0.75rem;
        }
        a {
            font-size:0.8rem;
            color: $secondary-color-shade-2;
            padding: 0.5rem 1rem;
        }
        .dropdown-pane {
            width: auto;
            padding: 0.3rem 0;
        }
    }
}
