$sidebar-filter-color: $dark-gray;

@mixin search-filter-drilldown-menu {
    .is-drilldown {
        text-align: left;
        max-width: 100% !important;
        .menu {
            color: $dark-gray;
            font-size: $small-font-size;
            li {
                input {
                    vertical-align: middle;
                    margin-left: $global-margin *0.2;
                    transform: scale(1.2);
                }
                a {
                    vertical-align: middle;
                    padding: $global-margin * 0.35 $global-margin * 0.5;
                    color: inherit;
                    display: inline-block;
                    width: 92%;
                    &::after {
                        right: 0;
                        border-color: transparent transparent transparent $dark-gray;
                    }
                    &::before {
                        border-color: transparent $dark-gray transparent transparent;
                    }
                }
                ul {
                    height: 400px;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
            }
        }
    }
}

@mixin search-filteri-kategorija {
    @include button-group();
    text-align: right;
    #{$buttongroup-child-selector} {
        // @include breakpoint(medium) {
        //     font-size: map-get($button-sizes, normal);
        //     width: 7rem;
        // }
        // font-size: map-get($button-sizes, small);
        // font-weight: 600;

        @include -zf-breakpoint-value('medium', $grid-column-gutter) {
            $padding: rem-calc($-zf-bp-value) / 4;
            padding-bottom: $padding;
            padding-top: $padding;
        }
        width: 5rem;
        @include button-style($dark-gray, auto, auto);
        &:not(.is-active) {
            @include button-dropdown(0.4em, $medium-gray, get-side($button-padding, right));
            @include button-hollow();
            @include button-hollow-style($medium-gray);
        }
        &.is-active {
            @include button-dropdown;
        }
    }
    .dropdown-pane {
        @include search-filter-drilldown-menu;
        width: 350px;
        padding: 0.5rem 1rem;
    }
}

.sidebar.search-block {
    // @include sb-block;

    .sidebar.search-form {
        input {
            box-shadow: none;
            @include -zf-breakpoint-value('medium', $grid-column-gutter) {
                $padding: rem-calc($-zf-bp-value) / 16;
                margin-bottom: $padding;
            }
            @include breakpoint(medium) {
                height: map-get(map-get(map-get($header-styles, medium), h2), font-size);
            }
            @include breakpoint(small) {
                height: map-get(map-get(map-get($header-styles, small), h2), font-size);
            }
            padding: 0.41rem;
            margin-top: 0;
            border-color: $medium-gray;
        }
    }
    .sidebar.search-filters {

        @include search-filteri-kategorija;
        #{$buttongroup-child-selector} {
            width: 100%;
            @include breakpoint(large) {
                @include button-expand;
            }
        }
        margin-bottom: 0;
    }
}

.in-ajax-reloading {
    opacity: 0.4;
}


.main-search-form {
    margin-top: map-get($grid-column-gutter, 'small') / 2;
    @include breakpoint('medium') {
        margin-top: map-get($grid-column-gutter, 'medium') / 2;
    }
    .button {
        // width: 8rem;
        @include button-expand;

        @include button-dropdown;
        @include breakpoint(medium) {
            padding: 0.85rem 0.5rem;
        }
        padding: 0.85rem 0.3rem;
        font-size: map-get($button-sizes, small);
        margin-bottom: 0.5*$global-margin;
        &::after {
            margin-left: 0.5rem;
        }
    }
    .tip-pretrage-pane {
        padding: 1px 0 0 0;
        width: $global-margin * 8;
        border: none;
        @include button-group;
        @include button-group-stack;
        .button {
            font-size: map-get($button-sizes, small);
            margin: 0 2px 2px 0;
            @include button-hollow();
            @include button-hollow-style();
            &:hover {
                @include button-style();
            }
        }
    }
}
.main-filter-bar {
    @include search-filteri-kategorija;
}
.main-search-results {
    // @include grid-column-row;
    // font-family: $text-font-family;
    .polje-search-ostalo {
        // @include grid-column-row;
        background-color: $polje-bg-color;
        color: $dark-gray;
        border-top: $dark-gray solid 1px;
        margin-bottom: 0.5*$global-margin;
        &:focus,
        &:hover {
            background-color: $polje-hover-color;
        }
        @each $name, $color in $category-colors {
            &.#{$name} {
            border-top: $color solid 1px;
            }
        }

        .title {
            font-family: $header-font-family;
            font-size: $polje-title-font-size;
            a {
                color: $polje-title-color;
            }
        }
        .datum {
            color: $polje-fg-info-color;
            font-size: $polje-datum-font-size;
            margin-top:$global-margin*0.6;
        }
    }
    // ul, ol {
    //     margin-left: 2*$global-margin;
    // }
}

.global-search-c {
    .global-search-group {
        %input-group-child {
            margin: 0;
            white-space: nowrap;
            display: table-cell;
            vertical-align: middle;
        }
        display: table;
        width:2rem;
        float: right;
        > :first-child {
            border-radius: 8px 0 0 8px;
        }
        > :last-child {
            border-radius: 8px;
        }

        > input {
            @extend %input-group-child;
            padding: 0 0.5*$global-margin;
            height: 1.9rem;
            border-color: $primary-color;
            background-color:$white;
            color: $black;
            &:focus {
                border: 1px solid $primary-color;
                box-shadow: none;
            }
            display: none;

        }

        > span:last-child {
            @extend %input-group-child;
            display: table-cell;
            text-align: center;
            padding: 0 $input-prefix-padding;
            background: $input-prefix-background;
            color: $input-prefix-color;
            border: $input-prefix-border;
            white-space: nowrap;

            height: 1.8rem;
            border-color: $primary-color;
            background-color:$primary-color;
            color: $medium-gray;
            cursor: pointer;
            &:hover {
                background-color:$secondary-color;
            }


        }
        &.otvoren {
            width: 19rem;
            > :last-child {
                border-radius: 0 8px 8px 0;
            }
            > input {
                display: table-cell;
            }
        }
    }
}


// .library-main-index-page .sb-main,
// .library-index-page .sb-main,
// .news-index-main-page .sb-main,
// .news-index-page .sb-main, {
//     @include search-form-index-page();
// }


// Stari tagovi sa strane
// .sidebar, .sb-main, .sb-extra {
//     .filteri-c {
//         @include breakpoint(medium) {
//             margin-top: $global-margin*2;
//         }
//         color: $sidebar-filter-color;
//         font-size: 100%;
//         .title,
//         .subtitle {
//             border-bottom: $sidebar-border;
//             .view-all {
//                 font-size: 80%;
//                 float: right;
//                 padding-top: $global-margin*0.2;
//                 a {
//                     color: $sidebar-filter-color;
//                     &:hover {
//                         text-decoration: underline;
//                     }
//                 }
//             }
//         }
//         .subtitle {
//             @include breakpoint(medium) {
//               font-size: 85%;
//               margin-top: $global-margin*0.7;
//             }
//             font-size: 70%;
//             color: $dark-gray;
//         }
//         .filteri,
//         .subfilteri {
//             @include menu-base;
//             @include menu-direction(vertical);
//             @include foundation-accordion-menu;
//             div {
//                 display: table-cell;
//                 vertical-align: super;
//                 width:100%;
//                 a {
//                     font-size: 85%;
//                     color: $default-category-color;
//                     padding: 0 $global-margin*0.2;

//                     &.active,
//                     &:hover {
//                         // text-decoration: underline;
//                         border: 1px solid $default-category-color;
//                         background: $default-category-color;
//                         color: foreground($default-category-color);
//                         padding: 0 $global-margin*0.2;
//                         border-radius: 3px;
//                     }
//                     &.semiactive {
//                         border: 1px solid $default-category-color;
//                         border-radius: 3px;
//                     }
//                     @each $name, $color in $category-colors {
//                       @if $name != primary {
//                         &.#{$name} {
//                             color: $color;
//                             &.active,
//                             &:hover {
//                                 border-color: $color;
//                                 background: $color;
//                                 color: foreground($color);
//                             }
//                             &.semiactive {
//                                 border-color: $color;
//                             }
//                         }
//                       }
//                     }
//                 }
//             }
//             li > a {
//                 display: table-cell;
//                 text-align: right;
//                 vertical-align: top;
//                 width: 100%;
//                 padding-left: 0;
//                 padding-right: $global-margin*0.5;
//             }
//             .is-accordion-submenu-parent > a {
//                 &::after {
//                     right: $global-margin*0.7;
//                     border-color: $sidebar-filter-color transparent transparent;
//                 }
//             }
//             @each $name, $color in $category-colors {
//               @if $name != primary {
//                 .is-accordion-submenu-parent > a.#{$name} {
//                     &::after {
//                         border-color: $color transparent transparent;
//                     }
//                 }
//               }
//             }
//         }
//         .subfilteri {
//             @include menu-nested($global-margin);
//             max-height:300px;
//             overflow-y:auto;
//             overflow-x: hidden;
//             div {
//                 a {
//                     font-size: 80%;

//                 }
//             }
//         }
//     }
// }
