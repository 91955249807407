// Slika sa naslovim/opisom ispod nje
@mixin image-with-title($img_width: 'auto') {
    img {
        margin-bottom: 0;
        @include -zf-breakpoint-value(auto, $grid-column-gutter) {
            $padding: rem-calc($-zf-bp-value) / 4;
            margin-top: $padding;
        }
    }
    .image-title,
    .image-description,
    .image-caption {
        i {
            font-size: 1.5em;
            color: $subheader-color;
            margin-right: 0.5em;
            vertical-align: middle;
        }
        clear:both;
        @include -zf-breakpoint-value(auto, $grid-column-gutter) {
            $padding: rem-calc($-zf-bp-value) / 2;
            padding: $padding/4  ;
        }
        font-size: $small-font-size;
        color: $subheader-color;
        @if $img_width != 'auto' {
            max-width: $img_width;
            width: $img_width;
        }

    }
}
@mixin image_in_stream {
    .img-wrapper {
        @include image-with-title(330px);
        display: block;
        position: relative;
        word-break: break-all;
        // left: -7*$global-margin;
        // margin-right: -6*$global-margin;
        @include -zf-breakpoint-value(auto, $grid-column-gutter) {
            $margin: rem-calc($-zf-bp-value) /2;
            &.left {
                float: left;
                margin: $margin/4 $margin $margin/2 0;
            }
            &.right {
                float:right;
                margin: $margin/4  $margin/2 $margin;
            }
        }
    }
}
@mixin video-with-title {
    video {
        margin:$global-margin/2 0  0 0;
    }
    .video-title {
        i {
            font-size: 1.5em;
            color: $subheader-color;
            margin-right: 0.5em;
            vertical-align: middle;
        }
        clear:both;
        @include -zf-breakpoint-value(auto, $grid-column-gutter) {
            $padding: rem-calc($-zf-bp-value) / 4;
            padding: 0 $padding;
        }
        font-size: $small-font-size;
        color: $subheader-color;
    }
}
@mixin video_in_stream {
    .vid-wrapper {
        @include video-with-title();
        display: block;
        position: relative;
        word-break: break-all;
        padding: 0;
        margin: 0;
    }
}

@mixin streamfield {
    @include image_in_stream();
    @include video_in_stream();
    .accordion-title {
        font-family: $header-font-family;
        &:hover, &:focus {
            color: $black;
        }
        @include breakpoint(small) {
            font-size:  map-get(map-get(map-get($header-styles, small), h5), font-size);;
        }
        @include breakpoint(medium) {
            font-size: map-get(map-get(map-get($header-styles, medium), h5), font-size);;;
        }
    }
    .accordion-content {
        padding-left: 1.25rem;
    }
}


.article {
    &.intro {
        font-family: $header-font-family;
        color: $subheader-color;
        font-size: 1.0735em;
        @include -zf-breakpoint-value(auto, $grid-column-gutter) {
            $padding: rem-calc($-zf-bp-value) / 4;
            margin-top: $padding;
            margin-bottom: $padding;
        }
    }
    &.image {
        @include image-with-title();
    }
    &.video {
        @include video-with-title();
    }
    &.content {
        // stream field
        @include streamfield();
        font-family: $text-font-family;
        font-size: 0.9275em;
    }
    &.datum,
    &.business-unit {
        color: $subheader-color;
        font-size: $small-font-size;
    }
}

.page-bottom-spacer {
    min-height: 3em;
    background-color: $white;
}

// Hover over data-href div
div[data-href] {
    cursor: pointer;
    cursor: hand;
}

.text-size-s {
    font-size: 0.8em;
}
.text-size-xs {
    font-size: 0.7em;
}

// TODO image in stream, video in stream ??accordion u clanku??
