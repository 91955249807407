
.kalendar.sidebar {
    // @include sb-block;
    .title {
        // @include sb-block-title;
    }
    .kalendar-main {
        line-height: $header-lineheight;
        // font-size: 90%;
        > .header {
            text-align:  center;
            // line-height: 2;
            > div {
                display: inline-block;
            }
            .month {
                margin: 0 $global-margin*0.5;
            }
            .btn-prev,
            .btn-next {
                font-size: 110%;
                color: $dark-gray;
                &:hover {
                    color: $primary-color;
                }
            }
            .btn-prev {
                float: left;
                margin-left: 5px;
            }
            .btn-next {
                float: right;
                margin-right: 5px;
            }
        }
        > table {
            // font-size: 90%;
            td {
                text-align: center;
                padding-left: 0.1*$global-margin;
                padding-right: 0.1*$global-margin;
            }
            thead td {
                color: $dark-gray;
                &.nedelja,
                &.subota {
                    color: $medium-gray;
                }
            }
            tbody {
                color: $medium-gray;
                .event {
                    color: $dark-gray !important;
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    &.council {
                        background: url('/static/home/img/kalendar_council.png');
                    }
                }
                .active {
                    background-color: $primary-color-shade-4;
                }
            }
        }
    }
    .events {
        font-size: $small-font-size;
        // font-size: 90%;
        .event {
            display: none;
            border: $sidebar-border;
            &.council {
                border-top-color: $tag-color-6;
                border-top-style: solid;
            }

            padding: 0.5*$global-margin;
            margin-bottom: 0.5*$global-margin;
            // font-size: 80%;
            .title {
                color: $black;
                border-bottom: none;
                font-weight: bold;
                // font-size: 110%;
            }
            .datum_od_do,
            .period_od_do,
            .opis ,
            .ucesnici ,
            .lokacija,
            .dp {
                color: $black;
                margin-top: 0.25*$global-margin;
                p {
                    display: inline;
                }
            }
        }
    }
}

.kalendar-bottom,
.kalendar-agenda {
    @include grid-column-row;
}
.kalendar-agenda {
    table {
        color: $darker-gray;
        // font-size: 80%;
        thead {
            th {
                background-color: $primary-color-shade-2;
                color: $white;
            }
        }
        th {
            background-color: $primary-color-shade-3;
            color: $darker-gray;
            white-space: nowrap;
            &.top-border {
                border-top: $white solid 1px;
            }
        }
        td, th {
            vertical-align: top;
            text-align: left;
            &.period {
                white-space: nowrap;
            }
        }
        tr.potisni {
            font-style: italic;
            th, td {
                color: $dark-gray;
            }
        }
        td.ucesnici {
            // font-size: 90%;
        }
    }
}
