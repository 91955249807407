$polje-title-color: $darker-gray;
$polje-title-font-size: 1.275em;
$polje-small-title-font-size: 1.0375em;
$polje-intro-font-size: 0.8em;
$polje-datum-font-size: 0.725em;
$polje-fg-color: $darker-gray;
$polje-bg-color: $light-gray;
// $polje-bg-color: $primary-color-shade-5;
$polje-hover-color: $light-gray-shade;
$polje-fg-info-color: $darker-gray;
$polje-margin: $global-margin*0.8;
$polje-datum-color: $dark-gray;

$polje-body-font-size: 90%;
$polje-fg-body-color: $darker-gray;

.n-polje {
    background-color: $polje-bg-color;
    color: $polje-fg-color;
    border-top: $default-bu-color solid 1px;
    > .columns {
        color: $black;
    }
    // padding-bottom: $polje-datum-font-size * 1.5;
    > div:not(.image) {
        @include -zf-breakpoint-value('medium', $grid-column-gutter) {
            $padding: rem-calc($-zf-bp-value) / 4;
            padding-left: $padding !important;
            padding-right: $padding !important;
        }

    }
    @each $name, $color in $category-colors {
        &.#{$name} {
        border-top: $color solid 1px;
        }
    }
    &[data-href]:focus,
    &[data-href]:hover {
        background-color: $polje-hover-color;
    }
    .image {
        img {
            width:100%;
            margin:0;
        }
    }
    .title {
        font-family: $header-font-family;
        font-style: $header-font-style;
        font-weight: $header-font-weight;
        text-rendering: $header-text-rendering;
        @include -zf-breakpoint-value(auto, $header-styles) {
            $header-defs: map-get($-zf-bp-value, 'h5');
            @if map-has-key($header-defs, font-size) {
                $font-size-temp: rem-calc(map-get($header-defs, font-size));
                font-size: $font-size-temp;
              } @else if map-has-key($header-defs, fs) {
                $font-size-temp: rem-calc(map-get($header-defs, fs));
                font-size: $font-size-temp;
              }
            @if map-has-key($header-defs, margin-top) {
                $margin-top-temp: rem-calc(map-get($header-defs, margin-top));
                margin-top: $margin-top-temp / 6;
              } @else if map-has-key($header-defs, mt) {
                $margin-top-temp: rem-calc(map-get($header-defs, mt));
                margin-top: $margin-top-temp / 6;
              }
        }
        line-height: $header-lineheight;
        // font-weight: bold;
        a {
            color: $polje-title-color;
        }
    }
    .title + .intro {
        margin-top: 0.64em;
    }
    .intro {
        font-family: $text-font-family;
        font-size: $polje-intro-font-size;
    }

    .datum,
    .kategorizacija,
    .funkcija {
        color: $polje-datum-color;
        font-size: $polje-datum-font-size;
        &.desni {
            text-align: right;
        }
    }
    .funkcija {
        line-height: 1.25em;
    }
    .funkcija-spacer {
        min-height: 3em;
    }
}
// .n-blok-sirina-8,
// .n-blok-sirina-9,
// .n-blok-sirina-10,
// .n-blok-sirina-11,
// .n-blok-sirina-12 {
//     .n-polje .title {
//         // font-size: $polje-title-font-size;
//     }
// }

.n-polje.novost,
.n-polje.dogadjaj,
.n-polje.person {
    position: relative;
    .title {
        .dogadjaj,
        .oblast {
            color: $dark-gray;
            a {
                color: $dark-gray;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .separator {
            color: $medium-gray;
        }
    }
    .datum,
    .kategorizacija,
    .funkcija {
        margin-top:$global-margin*0.6;
        position: absolute;
        bottom: 0;
    }
}
.n-polje.novost:not(.udarna):not(.poslednja):not(.obavjest) {
    // .title {
    //     @include breakpoint(medium) {
    //         font-size: 1.024rem
    //     }
    // }
    .image {
        img {
            filter: grayscale(0.25);
            -webkit-filter: grayscale(0.25);
        }
    }
    &:hover,
    &:focus {
        .image {
            img {
                filter: none;
                -webkit-filter: none;
            }
        }
    }
}
.n-polje.novost.udarna {
    .title {
        // @include -zf-breakpoint-value(auto, $header-styles) {
        //     $header-defs: map-get($-zf-bp-value, 'h5');
        //     @if map-has-key($header-defs, font-size) {
        //         $font-size-temp: rem-calc(map-get($header-defs, font-size));
        //         font-size: $font-size-temp;
        //       } @else if map-has-key($header-defs, fs) {
        //         $font-size-temp: rem-calc(map-get($header-defs, fs));
        //         font-size: $font-size-temp;
        //       }
        //     @if map-has-key($header-defs, margin-top) {
        //         $margin-top-temp: rem-calc(map-get($header-defs, margin-top));
        //         margin-top: $margin-top-temp / 6;
        //       } @else if map-has-key($header-defs, mt) {
        //         $margin-top-temp: rem-calc(map-get($header-defs, mt));
        //         margin-top: $margin-top-temp / 6;
        //       }
        // }
        &.big {
            $header-defs: map-get($-zf-bp-value, 'h4');
            @if map-has-key($header-defs, font-size) {
                $font-size-temp: rem-calc(map-get($header-defs, font-size));
                font-size: $font-size-temp;
              } @else if map-has-key($header-defs, fs) {
                $font-size-temp: rem-calc(map-get($header-defs, fs));
                font-size: $font-size-temp;
              }
            @if map-has-key($header-defs, margin-top) {
                $margin-top-temp: rem-calc(map-get($header-defs, margin-top));
                margin-top: $margin-top-temp / 6;
              } @else if map-has-key($header-defs, mt) {
                $margin-top-temp: rem-calc(map-get($header-defs, mt));
                margin-top: $margin-top-temp / 6;
              }
        }
        font-weight: 600;
    }
}
.n-polje.novost.poslednja {
    .image {
        img {
            filter: grayscale(0.5);
            -webkit-filter: grayscale(0.5);
        }
    }
    &:hover,
    &:focus {
        .image {
            img {
                filter: none;
                -webkit-filter: none;
            }
        }
    }
}
.n-polje.novost.obavjestenje {
    .n-polje.novost {
        .title {
            @include -zf-breakpoint-value(auto, $header-styles) {
                $header-defs: map-get($-zf-bp-value, 'h4');
                @if map-has-key($header-defs, font-size) {
                    $font-size-temp: rem-calc(map-get($header-defs, font-size));
                    font-size: $font-size-temp;
                  } @else if map-has-key($header-defs, fs) {
                    $font-size-temp: rem-calc(map-get($header-defs, fs));
                    font-size: $font-size-temp;
                  }
                @if map-has-key($header-defs, margin-top) {
                    $margin-top-temp: rem-calc(map-get($header-defs, margin-top));
                    margin-top: $margin-top-temp / 6;
                  } @else if map-has-key($header-defs, mt) {
                    $margin-top-temp: rem-calc(map-get($header-defs, mt));
                    margin-top: $margin-top-temp / 6;
                  }
            }
            line-height: $header-lineheight;
        }
        .intro {
            font-size: $small-font-size;
        }
    }
}

.n-polje.obavjestenje + .n-polje.obavjestenje,
.n-polje.banner + .n-polje.banner {
    @include -zf-breakpoint-value('medium', $grid-column-gutter) {
        $padding: rem-calc($-zf-bp-value) / 4;
        margin-top: $padding;
    }
}

.n-polje.document {
    background-color: $white;
    border-top: 1px solid $light-gray-shade;

    .title {
        font-family: $body-font-family;
        margin-bottom: $header-margin-bottom;
        // font-size: 1.0725em;
        // margin-top: 0;
        a:hover {
            text-decoration: underline;
        }
    }
}
.n-polje.sjednica {
    .datum {
        font-size: 1rem;
        color: $darker-gray;
    }
    .location {
        color: $darker-gray;
        font-size: $small-font-size;
    }
}

.n-polje.sjednica.search,
.n-polje.novost.search,
.n-polje.ostalo.search {
    background-color: $white;
    // border-top: 1px solid $light-gray-shade;
    border-top: lighten($default-bu-color, 30%) solid 1px;
    @each $name, $color in $category-colors {
        &.#{$name} {
        border-top: lighten($color, 30%) solid 1px;
        }
    }
    .title {
        a:hover {
            text-decoration: underline;
        }
    }
}

.banners-container {
    @include -zf-breakpoint-value('medium', $grid-column-gutter) {
        $padding: rem-calc($-zf-bp-value);
        margin-top: $padding;
        &:first-child {
            margin-top: $padding*1.1875;
        }
    }
    .intro {
        @include streamfield();
    }
    .not-boxed {
        .intro {
            font-size: $small-font-size;
        }
        .image img {
            width: auto;
            max-width: 100%;
        }
    }

}

.n-polje.banner {
    padding-bottom: 0;
    &.not-boxed {
        background-color: $white;
        border-top: 0;
    }
    &.no-left-right-padding {
        > div {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
}
