

#smallMenu {
    text-transform: lowercase;
    // font-weight: 600;

    background-color: $secondary-color-dark-1;
    .small-menu {
        a {
            color: $white;
            span {
                padding-bottom: 0.05em;
            }
            &:hover {
                span {
                    border-bottom: 1px solid;
                }
            }
        }

        .active > a {
            span {
                border-bottom: 1px solid;
            }
        }
        > li {
            > a {
                padding-left: 1.25em;
            }

            > ul {
                background-color: $secondary-color-shade-3;
                > li {
                    > a {
                        color: $darker-gray;
                        padding-left: 2.5em;
                        &::after {
                            border-color: $darker-gray transparent transparent;
                        }
                    }
                    > ul {
                        > li > a {
                            color: $darker-gray;
                            padding-left: 3.75em;
                        }
                    }
                }
            }
        }
    }
}
