// @import 'util/util';

// 1. Global
// ---------

@mixin add-foundation-agencija-colors() {
  @if map-has-key($foundation-palette, primary) {
    $primary-color: map-get($foundation-palette, primary) !global;
  }
  @if map-has-key($foundation-palette, primary-shade-1) {
    $primary-color-shade-1: map-get($foundation-palette, primary-shade-1) !global;
  }
  @if map-has-key($foundation-palette, primary-shade-2) {
    $primary-color-shade-2: map-get($foundation-palette, primary-shade-2) !global;
  }
  @if map-has-key($foundation-palette, primary-shade-3) {
    $primary-color-shade-3: map-get($foundation-palette, primary-shade-3) !global;
  }
  @if map-has-key($foundation-palette, primary-shade-4) {
    $primary-color-shade-4: map-get($foundation-palette, primary-shade-4) !global;
  }
  @if map-has-key($foundation-palette, primary-shade-5) {
    $primary-color-shade-5: map-get($foundation-palette, primary-shade-5) !global;
  }
  @if map-has-key($foundation-palette, secondary-dark-1) {
    $secondary-color-dark-1: map-get($foundation-palette, secondary-dark-1) !global;
  }
  @if map-has-key($foundation-palette, secondary) {
    $secondary-color: map-get($foundation-palette, secondary) !global;
  }
  @if map-has-key($foundation-palette, secondary-shade-1) {
    $secondary-color-shade-1: map-get($foundation-palette, secondary-shade-1) !global;
  }
  @if map-has-key($foundation-palette, secondary-shade-2) {
    $secondary-color-shade-2: map-get($foundation-palette, secondary-shade-2) !global;
  }
  @if map-has-key($foundation-palette, secondary-shade-3) {
    $secondary-color-shade-3: map-get($foundation-palette, secondary-shade-3) !global;
  }
  @if map-has-key($foundation-palette, secondary-shade-4) {
    $secondary-color-shade-4: map-get($foundation-palette, secondary-shade-4) !global;
  }

  @if map-has-key($foundation-palette, success) {
    $success-color: map-get($foundation-palette, success) !global;
  }
  @if map-has-key($foundation-palette, warning) {
    $warning-color: map-get($foundation-palette, warning) !global;
  }
  @if map-has-key($foundation-palette, alert) {
    $alert-color: map-get($foundation-palette, alert) !global;
  }

  @if map-has-key($foundation-palette, tag-1) {
    $tag-color-1: map-get($foundation-palette, tag-1) !global;
  }
  @if map-has-key($foundation-palette, tag-2) {
    $tag-color-2: map-get($foundation-palette, tag-2) !global;
  }
  @if map-has-key($foundation-palette, tag-3) {
    $tag-color-3: map-get($foundation-palette, tag-3) !global;
  }
  @if map-has-key($foundation-palette, tag-4) {
    $tag-color-4: map-get($foundation-palette, tag-4) !global;
  }
  @if map-has-key($foundation-palette, tag-5) {
    $tag-color-5: map-get($foundation-palette, tag-5) !global;
  }
  @if map-has-key($foundation-palette, tag-6) {
    $tag-color-6: map-get($foundation-palette, tag-6) !global;
  }
  @if map-has-key($foundation-palette, tag-7) {
    $tag-color-7: map-get($foundation-palette, tag-7) !global;
  }
}

$foundation-palette: (
  primary: #427AC6,
  primary-shade-1: #6895D1,
  primary-shade-2: #8EAFDD,
  primary-shade-3: #B3CAE8,
  primary-shade-4: #D9E4F4,
  primary-shade-5: #F7F8FA,
  secondary-dark-1: #1A3460,
  secondary: #213E6F,
  secondary-shade-1: #4D658C,
  secondary-shade-2: #7A8BA9,
  // secondary-shade-3: #A6B2C5,
  secondary-shade-3: #D9D9D9,
  // secondary-shade-4: #D3D8E2,
  secondary-shade-4: #E6E6E6,

  success: #7AC642,
  warning: #F9B962,
  alert: #C64C42,

  tag-1: #04449A,
  tag-2: #9A0450,
  tag-3: #9A1C04,
  tag-4: #9A9904,
  tag-5: #379A04,
  tag-6: #CC7500,
  tag-7: #6C08B5,
);
@include add-foundation-agencija-colors;

$light-gray: #f6f6f6;
$light-gray-shade: #eaeaea;
$medium-gray: #cccccc;
$dark-gray: #63717a;
$darker-gray: #353535 !default;

$black: #0a0a0a;
$white: #fefefe;

$category-colors: (
    tag-color-1: $tag-color-1,
    tag-color-2: $tag-color-2,
    tag-color-3: $tag-color-3,
    tag-color-4: $tag-color-4,
    tag-color-5: $tag-color-5,
    tag-color-6: $tag-color-6,
    tag-color-7: $tag-color-7,

    warning: $warning-color,
    council: $tag-color-6,
    elections: $tag-color-2,
    press: $tag-color-3,
    announcement: $tag-color-6,
    other: $primary-color,
);

@each $name, $color in $category-colors {
  @if $name != primary {
    .#{$name} {
        color: $color !important;
    }
  }
}
$default-bu-color: $primary-color;
$default-category-color: $secondary-color-shade-2;

$body-background: $white;
$body-font-color: $black;
$body-font-family: 'Liberation Serif', 'Times New Roman', serif;

$global-margin: 0.8rem;
// $global-padding: 1rem;
// $global-position: 1rem;
$global-font-size: 15.5px;
$global-lineheight: 1.45;
$global-radius: 2px;
// $global-menu-padding: 0.7rem 1rem;
// $global-menu-nested-margin: 1rem;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$grid-column-gutter: (
  small: 18px,
  medium: 25px,
);

// 4. Base Typography
// ------------------

$header-font-family: $body-font-family;
$text-font-family:  $body-font-family;
$paragraph-lineheight: 1.45;
$list-lineheight: $paragraph-lineheight;
$header-lineheight: 1.2;
$header-margin-bottom: 0.41rem;
$header-styles: (
  small: (
    'h1': ('font-size': 2.074rem, 'margin-top': 0),
    'h2': ('font-size': 1.728rem, 'margin-top': 1.25em),
    'h3': ('font-size': 1.44rem, 'margin-top': 1.25em),
    'h4': ('font-size': 1.2rem, 'margin-top': 1.25em),
    'h5': ('font-size': 1rem, 'margin-top': 1.25em),
    'h6': ('font-size': 0.833rem, 'margin-top': 1.25em),
  ),
  medium: (
    'h1': ('font-size': 2.441rem, 'margin-top': 0),
    'h2': ('font-size': 1.953rem, 'margin-top': 1.25em),
    'h3': ('font-size': 1.563rem, 'margin-top': 1.25em),
    'h4': ('font-size': 1.25rem, 'margin-top': 1.25em),
    'h5': ('font-size': 1rem, 'margin-top': 1.25em),
    'h6': ('font-size': 0.8rem, 'margin-top': 1.25em),
  ),
);
$small-font-size: 0.8rem;
$paragraph-margin-bottom: 0.41rem;
// 7. Accordion
// ------------

$accordion-plusminus: false;
$accordion-item-color: $black;
$accordion-item-background-hover: $light-gray;
$accordion-item-padding: $global-margin $global-margin $global-margin 0;
$accordion-content-border: 0px;
$accordion-content-color: $body-font-color;
$accordion-content-padding: $global-margin*0.5 0 0 0;

// 8. Accordion Menu
// -----------------

$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $white;


// 18. Dropdown Menu
// -----------------
// sekundarni meni
$dropdownmenu-arrows: false;
$dropdownmenu-arrow-color: $white;
$dropdownmenu-arrow-size: 6px;
$dropdownmenu-arrow-padding: 1.5em;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: $light-gray;
$dropdownmenu-submenu-background: $dropdownmenu-background;
$dropdownmenu-padding: 2em;
$dropdownmenu-nested-margin: 1em;
$dropdownmenu-submenu-padding: $dropdownmenu-padding;
$dropdownmenu-border: transparent;
$dropdown-menu-item-color-active: $white;
$dropdown-menu-item-background-active: transparent;


// 23. Menu
// --------

$menu-item-background-active: none;

// 27. Pagination
// --------------

$pagination-item-color: $primary-color;
$pagination-radius: 15px;
$pagination-item-background-current: $primary-color;
$pagination-arrows: true;
$pagination-mobile-items: true;

// 50. Table
// ---------

$table-padding: rem-calc(4 5 5);

// 53. Title Bar
// -------------

$titlebar-background: $secondary-color-dark-1;
$titlebar-icon-color-hover: $white;

// 54. Tooltip
// -----------

$tooltip-background-color: $medium-gray;
$tooltip-color: $black;
$tooltip-padding: 0.25rem;
$tooltip-pip-width: 0.25rem;
$tooltip-radius: 0;

// 55. Top Bar
// -----------

$topbar-padding: 0rem;
$topbar-background: $primary-color;


// 100. Sidebar
// ------------
$sidebar-top-margin: $global-margin*5 !default;
$sidebar-border: $medium-gray dotted 1px;
$sidebar-div-padding: $global-margin/5 0 $global-margin/5 0;
