// matrica blokova
// $matrica-margin: $global-margin*0.7;

.n-matrica {
    .n-blok {
        @include breakpoint('small') {
            margin-bottom: map-get($grid-column-gutter, 'small') / 4;
            &:not(.n-blok-visina-1) {
                :not(:last-child) {
                    margin-bottom: map-get($grid-column-gutter, 'small') / 4;
                }
            }
        }
        @include breakpoint('medium') {
            $padding: map-get($grid-column-gutter, 'medium') / 8;
            &:first-child:not(:last-child) {
                padding-right: $padding;
            }
            &:not(:first-child):not(:last-child) {
                padding-left: $padding;
                padding-right: $padding;
            }
            &:last-child:not(:first-child){
                padding-left: $padding;
                &.end {
                    padding-right: $padding;
                }
            }
            &:first-child:last-child.end {
                padding-right: $padding;
            }
            &:not(:last-child),
            &.end {
                border-right: $light-gray-shade solid 1px;
            }
        }
    }
}
.n-matrica + .n-matrica {
    &:not(.compressed) {
        @include breakpoint('medium') {
            margin-top: map-get($grid-column-gutter, 'medium') / 4;
        }
    }
}
