@mixin org-graph {
    // .org-graph {
    //     background: $light-gray;
    // }

    // .org-graph-item {

    //     &.is-active {
    //         background: $light-gray-shade;
    //     }
    // }
    .org-graph-item-title {
        display: block;
        overflow: auto;
        padding: $global-margin*0.25 $global-margin*0.25 $global-margin*0.5 $global-margin*0.5;
        font-family: $body-font-family;
        color: $darker-gray;
        position: relative;

        border-top: $default-bu-color solid 1px;
        @each $name, $color in $category-colors {
            @if $name != primary {
                &.#{$name} {
                  border-top: $color solid 1px;
                }
            }
        }

        .subheader {
            font-size: 92%;
            float:right;
            margin:0 0 0 $global-margin*0.5;
        }



    }
    a.org-graph-item-title {
        padding-left: $global-margin*3;
        &:hover,
        &:focus {
            // background-color: $medium-gray;
            color: $black;
            text-decoration: underline;
        }
        i {
            margin-left: $global-margin*1;
            margin-right: $global-margin*1;
            position: absolute;
            top: 50%;
            left:0;
            margin-top: -1rem;
            font-size: 130%;
            color: $primary-color-shade-2;
        }
        @each $name, $color in $category-colors {
            @if $name != primary {
                &.#{$name} i {
                  color: $color;
                }
            }
        }
    }

    .org-graph-item-content {
        font-size:90%;
        // background: $light-gray;
        margin-left: $global-margin*3;
        display: none;
    }
}

@include org-graph();

// .organization-index-page,
// .organization-page {
//     .main {
//         // @include regular_article();
//         @include org-graph();

//         .organizations {
//             margin: 0 $global-margin;
//         }
//     }
// }
// .person-page {
//     .main {
//         // @include regular_article();
//         .intro {
//             margin-top: $global-margin/4;
//         }
//         .image {
//             img {
//                 width: 100%;
//             }
//         }
//         .personaldata {
//             @include grid-column-row;
//             font-size: 85%;
//             color: $darker-gray;
//             .plabel {
//             }
//             .pdata  {
//             }
//         }
//     }
// }

// .sidebar.org-siblings {
//     @include breakpoint(small) {
//         margin-top: $global-margin;
//     }
//     @include breakpoint(medium) {
//         margin-top: $global-margin*2;
//     }
//     color: $dark-gray;
//     .page_subtitle {
//         font-size: 95%;
//         border-bottom: $sidebar-border;
//     }
//     .org-item {
//         font-size: 85%;
//         padding-left: $global-margin*1.7;
//         text-indent:  - $global-margin*0.7;
//         i {
//             margin-right: $global-margin*0.2
//         }
//         a {
//             color: $dark-gray;
//             &:hover {
//                 text-decoration: underline;
//             }
//         }
//         .org-subitem {
//             margin-left: $global-margin;
//         }
//     }
// }
.sidebar.org-siblings {
    @include breakpoint(small) {
        margin-top: $global-margin;
    }
    @include breakpoint(medium) {
        margin-top: $global-margin*2;
    }
    color: $dark-gray;
    li {
        font-size: $small-font-size;
        // padding-left: $global-margin*1.7;
        // text-indent:  - $global-margin*0.7;
        i {
            // margin-right: $global-margin*0.2;
            color: $darker-gray;
            padding-right: 0.4rem;
            display:table-cell;
        }
        span {
            display: table-cell;
        }
        a {
            color: $dark-gray;
            @include breakpoint('small') {
                $padding: map-get($grid-column-gutter, 'small') / 8;
                padding: $padding $padding/2
            }
            @include breakpoint('medium') {
                $padding: map-get($grid-column-gutter, 'medium') / 8;
                padding: $padding 0
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .submenu-toggle {
        height: 20px;
        &::after {
            border-color: $secondary-color transparent transparent;
        }
    }
    li.active,
    li.open {
        > a {
            font-weight: 600;
        }
    }
}
