.content {
    table {
        font-size: 90%;
        thead {
            th {
                background-color: $primary-color-shade-2;
                color: $white;
            }
        }
        th {
            background-color: $primary-color-shade-3;
            color: $darker-gray;
            white-space: nowrap;
            &.top-border {
                border-top: $white solid 1px;
            }
        }
        td, th {
            vertical-align: top;
            text-align: left;
            &.period {
                white-space: nowrap;
            }
        }
        tr.potisni {
            font-style: italic;
            th, td {
                color: $dark-gray;
            }
        }
    }
}
