@import 'stari_imovinski_kartoni/app_settings';
$sik-list-margin: $global-margin*0.7;
$sik-list-font-size: 0.9em;
$sik-list-small-font-size: 80%;

$sik-field-margin: $global-margin*0.7;
$sik-prijava-font-size: 0.9em;

.s-ikartoni-main {
    .search,
    .list-headers,
    .list-data {
        // margin-left: 0.5*$global-margin !important;
        // margin-right: 0.5*$global-margin !important;
        padding-left: 0;
        padding-right: 0;
    }
    .search {
        .text,
        .submit {
            padding-left: 0.35*$global-padding;
            padding-right: 0.35*$global-padding;
        }
    }
    .list-headers {
        font-weight: bold;
        border-bottom: $medium-gray solid 1px;
        margin-bottom: $global-margin*0.2;
        padding-bottom: $global-margin*0.2;
        .naziv,
        .godina,
        .prijava,
        .vrsta {
            font-size: $sik-list-small-font-size;
            padding-left: 0.35*$global-padding;
            padding-right: 0.35*$global-padding;
        }
        .godina {
            text-align: right;
        }
    }
    .list-data {
        .naziv,
        .godina,
        .prijava,
        .vrsta {
            padding-left: 0.35*$global-padding;
            padding-right: 0.35*$global-padding;
            font-size: $sik-list-small-font-size;
        }
        .naziv {
            font-weight: bold;
            >span {
                font-weight: normal;
                $sik-list-font-size: 90%;
            }
        }
        .godina {
            text-align: right;
        }
        .vrsta {
            .druga-funkcija {
                color: $dark-gray;
            }
        }
    }
    .pagination-c {

        // border-top: $medium-gray solid 1px;
        margin-top: $global-margin*0.5;
        padding-top: $global-margin*0.5;
    }
}
.s-ikarton{
    > .title,
    .izvjestaji,
    .sub-title,
    .intro,
    .prijava {
        // margin-left: 0.5*$global-margin !important;
        // margin-right: 0.5*$global-margin !important;
        padding-left: 0;
        padding-right: 0;
    }
    .title {
        span.ostala-imena {
            font-size: 0.5em;
            color: $dark-gray;
        }
    }
    .izvjestaji {
        font-size: 0.9em;
        margin-bottom: $global-margin;
        color: $dark-gray;
        .blok-title,
        .blok-values {
            padding-left: 0.35*$global-padding;
            padding-right: 0.35*$global-padding;
        }
        @include breakpoint(small) {
            .blok-title {
                text-align: left;
            }
        }
        @include breakpoint(medium) {
            .blok-title {
                text-align: right;
            }
        }
        .blok-values {
            a {
                color: $dark-gray;
                margin-left: $global-margin*0.5;
                &.active,
                &:hover {
                    // text-decoration: underline;
                    color: $black;
                    font-weight: bold;
                }
            }

        }
    }
    .intro {
        margin-bottom: $global-margin;
    }
    .prijava {
        margin-bottom: $global-margin*3;
        .field {
            // margin-right: 0 !important;
            // margin-left: 0 !important;
            .title,
            .value {
                padding-left: 0.35*$global-padding;
                padding-right: 0.35*$global-padding;
            }
            border-bottom: $medium-gray solid 1px;
            padding: $global-margin*0.1 0;
            .title {
                color:$dark-gray;
                font-size: 80%;
            }
            .value {
                font-size: $sik-prijava-font-size;
                &.naziv-lica {
                    font-weight: bold;
                    font-size: 100%;
                }
            }
        }
    }
}
